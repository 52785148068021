import React, { useState } from 'react'
import Layout from 'src/components/Layout'
import ArticlesByCategories from './articlesByCategories/_index'
import { Hero } from 'src/pages/sections/hero/_index'
import { navigate } from 'gatsby'
import { View } from 'src/types/View'

interface CategoriesPageProps {
  params: {
    categorie: string
  }
}

const CategoriesPage = (req: CategoriesPageProps) => {
  const [submitedSearch, setSubmitedSearch] = useState('')

  const contentMetas = {
    slug: '',
    metaTitle: 'Inter. Simplifica a vida.',
    metaDescription: '',
    noIndexOption: true,
    hideOpenAccountBottomForm: true,
    hideBabiChat: false,
  }

  return (
    <Layout pageContext={contentMetas}>
      <Hero
        isHomePage
        softView={true}
        submitedSearch={submitedSearch}
        setSubmitedSearch={setSubmitedSearch}
        view={View.ArticleByCategory}
        setView={(view) => {
          if (View.Search) {
            navigate(`/search?term=${submitedSearch}`)
          } else {
            navigate('/')
          }
        }}
        sendDatalayerEvent={() => {}}
        hasHeaderTitle={false}
      />

      <ArticlesByCategories categorieTitle={req.params.categorie}/>
    </Layout>
  )
}

export default CategoriesPage