import React, { useEffect, useState } from 'react'
import { normalizeString } from 'src/utils/normalizeString'
import { 
  SubcategoryAcordion, 
  SubcategoryHeader, 
  Title, 
  SubItemList, 
  SubcategoryItemLisTitle, 
  SubcategoryItemList, 
  SubcategoryItemListHeader, 
} from './SubcategoriesMenu.styles'
import { navigate } from "gatsby"
import subcategoriesJson from 'src/assets/data/faq-babi/subcategories/subcategories.json'
import OrangeIcon from '../Icons'

interface SubcategoryAcordionItemProps {
  title: string
  selectedCategory: string
}

export const SubcategoryAcordionItem = ({ title, selectedCategory }: SubcategoryAcordionItemProps) => {
  const normalizeTitle = normalizeString(title)
  const [ isOpenAccordion, setIsOpenAccordion ] = useState(false)

  function avoidEmojiInString(text: string) {
    const emojiPattern = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
    const trailingSpacePattern = /\s+$/g;

    const parsedString = text.replace(emojiPattern, '').replace(trailingSpacePattern, '')
    return parsedString
  }

  const handleClick = () => {
    if (subcategoriesJson[title].length === 0) {
      return navigate(`/categories/${normalizeTitle}`)
    }
    setIsOpenAccordion(!isOpenAccordion)
  }

  const handleClickSubcategorie = (subtitle: string) => {
    const removeEmoji = avoidEmojiInString(subtitle)
    const normalizeSub = normalizeString(removeEmoji)

    navigate(`/categories/${normalizeTitle}?sub=${normalizeSub}`)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    if (normalizeTitle === normalizeString(selectedCategory)) {
      setIsOpenAccordion(true)
    } else {
      setIsOpenAccordion(false)
    }
  }, [selectedCategory])


  if (subcategoriesJson[title].length === 0) {
    return <></>
  }

 
 

  return (
    <SubcategoryAcordion>
      <SubcategoryHeader onClick={handleClick} isActive={false}>
        <Title>{title}</Title>
        <div>
          <OrangeIcon size='MD' color='#ff7a00' icon={isOpenAccordion ? 'chevron-right' : 'chevron-down'} />
        </div>
      </SubcategoryHeader>
      {subcategoriesJson[title].length > 0 && isOpenAccordion && (
        <SubItemList isActive={false}>
          {subcategoriesJson[title]?.map(subItem => (
            <SubcategoryItemList onClick={() => handleClickSubcategorie(subItem.title.pt)}>
              <SubcategoryItemListHeader>
                <SubcategoryItemLisTitle>{avoidEmojiInString(subItem.title.pt)}</SubcategoryItemLisTitle>
              </SubcategoryItemListHeader>
            </SubcategoryItemList>
          ))}
        </SubItemList>
      )}
    </SubcategoryAcordion>
  )
}