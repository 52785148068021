import React, { useEffect, useState } from 'react'
import { navigate } from "gatsby"
import { 
  AccodionsList, 
  Section, 
  SubcategoryNavBar, 
  Back, 
  BreadCrumbWrapper, 
  Card, 
  CardBreadCrumb, 
  CardDescription, 
  CardTitle, 
  Divisor, 
  DivisorDashed, 
  ViewMoreButton, 
  NotFoundArticle 
} from './style'

import OrangeIcon from 'src/components/Icons'
import { ArticlesModel, ArticlesPropsModel } from 'src/pages/types';
import { normalizeString } from 'src/utils/normalizeString';
import { paginateHelper } from 'src/utils/paginateHelper'
import subcategoriesJson from 'src/assets/data/faq-babi/subcategories/subcategories.json'
import { getParameterByName } from 'src/utils/getParametersByName'

import articles from 'src/assets/data/faq-babi/articles.json'

import image404 from 'assets/images/404.png'
import { SubcategoryAcordionItem } from 'src/components/SubcategorieMenu/SubcategoriesMenu';

interface ArticlesByCategories {
  categorieTitle: string
}

const ArticlesByCategories = ({ categorieTitle }: ArticlesByCategories) => {
  const [page, setPage] = useState(1)
  const [articleByPage, setArticleByPage] = useState<Array<ArticlesPropsModel>>([])

  const articlesList: ArticlesModel = articles
  const handleBackToHome = () => navigate('/')

  const selectedCategory: string = Object.keys(articles).find((item) => {
    return normalizeString(item) === categorieTitle
  }) as string

  const term = getParameterByName('sub')
  const articlesCategory = articlesList[selectedCategory]

  const handleAddMore = () => setPage((prev) => prev + 1)

  useEffect(() => {
    if (term) {
      setPage(1)
      const subArticle = articlesCategory?.filter(item => normalizeString(item.subcategoryTitle || "") === term)
      const pagedArticle = paginateHelper(subArticle, 10, 1)
      setArticleByPage(pagedArticle)
    } else {
      setPage(1)
      const pagedArticle = paginateHelper(articlesCategory, 10, 1)
      setArticleByPage(pagedArticle)
    }
  }, [articlesCategory, term])

  useEffect(() => {    
    if(term) {
      const subArticle = articlesCategory?.filter(item => normalizeString(item.subcategoryTitle || "") === term)

      if (page === 1) {
        const pagedArticle = paginateHelper(subArticle, 10, 1)
        setArticleByPage(pagedArticle)
      } else {
        const pagedArticle = paginateHelper(subArticle, 10, page)
        setArticleByPage(prevArticles => {
          const mergedArticles = [...prevArticles, ...pagedArticle]
          return mergedArticles
        })
      }
    } else {
      if (page === 1) {
        const pagedArticle = paginateHelper(articlesCategory, 10, 1)
        setArticleByPage(pagedArticle)
      } else {
        const pagedArticle = paginateHelper(articlesCategory, 10, page)
        setArticleByPage(prevArticles => {
          const mergedArticles = [...prevArticles, ...pagedArticle]
          return mergedArticles
        })
      }
    }
    
  }, [page])

  function reorderObjectByCategory(object: any) {
    for (const key in object) {
      if (object.hasOwnProperty(key) && normalizeString(key) === categorieTitle) {
        const reorderedObject = {
          [key]: object[key],
        };
  
        for (const otherKey in object) {
          if (otherKey !== key && object.hasOwnProperty(otherKey)) {
            reorderedObject[otherKey] = object[otherKey];
          }
        }
  
        return reorderedObject;
      }
    }
  
    return object;
  }

  const reorderedSub = reorderObjectByCategory(subcategoriesJson)
  
  return (
    <Section className='container'>
      <div className='row'>
        <div className='col-12'>
          <Divisor />
          <Back onClick={handleBackToHome}>
            <div>
              <OrangeIcon size='MD' color='#ff7a00' icon='chevron-left' />
            </div>
            Voltar
          </Back>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-5'>
          <SubcategoryNavBar>
            <h2>{selectedCategory}</h2>
            <AccodionsList>
              {Object.keys(reorderedSub).map(item => {
                if (!articlesList[item]) {
                  return;
                }

                return <SubcategoryAcordionItem title={item} selectedCategory={selectedCategory || ''} />
              })}
            </AccodionsList>

          </SubcategoryNavBar>
        </div>
        <div className="col-7">
          <BreadCrumbWrapper className='mb-4'>
            <CardBreadCrumb>Central de Relacionamento</CardBreadCrumb>
            <div>
              <OrangeIcon size='SM' color='#6A6C72' icon='chevron-right' />
            </div>
            <CardBreadCrumb>{selectedCategory}</CardBreadCrumb>
          </BreadCrumbWrapper>
          {articleByPage.length === 0 && (
            <NotFoundArticle>
              <img src={image404} width={220} height={220}/>
              Não encontramos artigos relacionados a esse tópico.</NotFoundArticle>
          )}
          {articleByPage?.map((item: ArticlesPropsModel) => {
            const parseTitle = normalizeString(item.title)
            const constructUrlLink = `/${categorieTitle}/${parseTitle}`

            return (
              <>
                <Card
                  key={constructUrlLink}
                  href={constructUrlLink}
                >
                  <CardTitle>{item.title}</CardTitle>
        
                  <CardDescription dangerouslySetInnerHTML={{  __html: item.content}}></CardDescription>
              
                  <BreadCrumbWrapper>
                    <CardBreadCrumb>Central de Relacionamento</CardBreadCrumb>
                    <div>
                      <OrangeIcon size='SM' color='#6A6C72' icon='chevron-right' />
                    </div>
                    <CardBreadCrumb>{selectedCategory}</CardBreadCrumb>
                  </BreadCrumbWrapper>
                </Card>
                <DivisorDashed />
              </>
            )
          })}
          {articleByPage.length >= 10 && <ViewMoreButton onClick={handleAddMore}>
            Ver mais artigos
            <div>
              <OrangeIcon size='MD' color='#ff7a00' icon='chevron-down' />
            </div>
          </ViewMoreButton>}
        </div>
      </div>
    </Section>
  )
}

export default ArticlesByCategories